<template>
  <div class="checkbox-btn row middle-xs" @click="onClick">
    <div :class="`${isChecked ? 'tick-icon' : ''}`">
      <font-awesome-icon
        v-if="isChecked"
        :icon="['fas', `check-${icon}`]"
        class="check-on checkbox-active"
      />
      <font-awesome-icon
        v-if="!isChecked"
        :icon="['fas', icon]"
        class="check-off checkbox-inactive"
      />
    </div>
    <span v-if="label">{{ label }}</span>
  </div>
</template>
<script>
import KCheckboxMixin from 'adready-vue/components/mixins/k-checkbox-mixin';

export default {
  name: 'KCheckbox',
  mixins: [KCheckboxMixin],
  props: {
    icon: {
      required: false,
      type: String,
      default: 'square',
    },
    isRadio: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.isRadio ? this.checked : this.internalValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.light-theme .checkbox-btn {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // change the color for the tick icon by changing the background color for the before and after css
  .tick-icon {
    position: relative;
    &::before {
      background-color: #212325;
    }
    &::after {
      background-color: #212325;
    }
  }
  svg.checkbox-inactive {
    border: 1px solid rgba(150, 150, 150, 0.4) !important;
  }
  svg.checkbox-active {
    border: 1px solid #212325;
  }
}
.checkbox-btn {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // change the color for the tick icon by changing the background color for the before and after css
  .tick-icon {
    position: relative;
    &::before {
      position: absolute;
      left: -1px;
      top: 20%;
      height: 8px;
      width: 3px;
      background-color: #fff;
      content: '';
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
      border-radius: 1px;
    }
    &::after {
      position: absolute;
      left: 0px;
      bottom: 8px;
      height: 3px;
      width: 15px;
      background-color: #fff;
      content: '';
      transform: translateX(10px) rotate(-48deg);
      transform-origin: left bottom;
      border-radius: 1px;
    }
  }

  svg {
    font-size: 17px;
    &.checkbox-active {
      color: #1a1f23;
      border: 2px solid rgba(150, 150, 150, 0.4);
      border-radius: 50px;

      path {
        display: none;
      }
    }
    &.checkbox-inactive {
      border: 2px solid rgba(150, 150, 150, 0.4);
      border-radius: 50px;
      path {
        display: none;
      }
    }
  }

  span {
    margin: 0 10px;
    font-size: 12px;
  }
}

.product-type-slider .checkbox-btn span {
  margin: 0 0 0 5px;
  font-size: inherit;
}
</style>
